import ContractPricePromotionDisplay from "../../../Interfaces/PromoMatrix/ContractPricePromotionDisplay";
import StandardPromotionDisplay from "../../../Interfaces/PromoMatrix/StandardPromotionDisplay";
import * as types from "./../../Types/ProductDetailsTypes";

export type ProductDetailsStateType = {
    productIdToLoad: number | null;
    showProductDetailsModal: boolean;
    isFetchingProductDetails: boolean;
    product: ProductDetails | null;
    message: string;
    productNotFound: boolean;
};

export const initialState: ProductDetailsStateType = {
    productIdToLoad: null,
    showProductDetailsModal: false,
    isFetchingProductDetails: false,
    product: null,
    message: "",
    productNotFound: false,
};

export type ProductDetailImage ={
    thumbnail: string;
    medium: string;
    full: string;
}

export type ProductDetailsOption = {
    optionId: number;
    sizeId: number | null;
    uomId?: number;
    code: string;
    optionCode: string;
    option1: string;
    option2: string;
    option3: string | null;
    size: string | null;
    uomSize?: number;
    price: number;
    retailPrice: number;
    specialPrice: number;
    contractPrice: number;
    uomPrice: number;
    lineItemDiscount: number;
    image: string | null;
    stock: number;
    sizeRangeId: number | null;
    productCategories: number[];
    commonPromotions: string | null;
    standardPromotions: StandardPromotionDisplay[];
    contractPricePromotions: ContractPricePromotionDisplay[];
    incomingDate: string | null;
    weight: number;
    productQuantity: number;
    baseUnitPrice?: number;
  };


export type ProductDetails = {
    brand: string;
    stock: number;
    description: string;
    images: ProductDetailImage[];
    option1Name: string;
    option2Name: string;
    option3Name: string | null;
    currencyCode: string;
    taxStatus: string;
    taxRate: number | null;
    minPrice: number;
    maxPrice: number;
    retailPrice: number;
    minSpecialPrice: number;
    maxSpecialPrice: number;
    allowOverselling: boolean;
    isNonStock: boolean;
    options: ProductDetailsOption[];
  };

export const ProductDetailsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): ProductDetailsStateType => {
    switch (action.type) {
        case types.Fetching_Product_Details:
            return {
                ...state,
                isFetchingProductDetails: true,
                message: "",
                productNotFound: false,
            };
        case types.Fetch_Product_Details:
            return {
                ...state,
                isFetchingProductDetails: false,
                product: action.payload.product,
                message: "",
                productNotFound: false,
            };
        case types.Fetch_Product_Error:
            return {
                ...state,
                isFetchingProductDetails: false,
                message: action.payload.message,
                productNotFound: action.payload.productNotFound,
            };
        case types.UpdateProductOptionDisplayPrice:

        let product = state.product;

        let productOptions: ProductDetailsOption[] = action.payload;

        let updatedOptions;
    
        if (product && product.options) {

            let isUomProduct = product.options.filter(x => x.uomId && x.uomId > 0).length > 0;

            if(isUomProduct){
                updatedOptions = product.options.map((x: ProductDetailsOption) => {
                    return {
                        ...x,
                        lineItemDiscount: action.payload[0].lineItemDiscount,
                        contractPrice: action.payload[0].contractPrice,
                    };
                });

            }else{
                updatedOptions = product.options.map((x: ProductDetailsOption) => {
                    let match = productOptions.find((option: ProductDetailsOption) => {
                        return option.code === x.code
                    });
                    if (match) {
                        return {
                            ...x,
                            lineItemDiscount: match.lineItemDiscount,
                            contractPrice: match.contractPrice,
                        };
                    }
                    return x;
                });
            }
    
            return {
                ...state,
                product: {
                    ...product,
                    options: updatedOptions,
                },
            };
        }
    
        return state;
    
        case types.Show_Product_Details_Modal:
            return {
                ...state,
                showProductDetailsModal: true,
            };
        case types.Hide_Product_Details_Modal:
            return {
                ...state,
                showProductDetailsModal: false,
            };
        case types.Set_Product_Id_To_Load:
            return {
                ...state,
                productIdToLoad: action.payload,
            };
        default:
            return state;
    }
};
