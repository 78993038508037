import React from 'react'
import PriceDisplay from '../../Global/PriceDisplay';

import './Styles/_ProductOptionUOMUnitPrice.scss';
import { ProductDetailsOption } from '../../../Redux/Reducers/App_Reducers/ProductDetailsReducer';
import { getContractPrice } from '../../../Utils/ProductsHelper';

interface propsModel {
    option : ProductDetailsOption
}

export const ProductOptionUOMUnitPrice = (props: propsModel) => {
    const { option } = props;
    
    let unitPrice = option.contractPrice
        ? option.contractPrice
        : (option.price / (option.uomSize ?? 1));

    if(unitPrice > 0) {
        return (
            <div className="product-details-option-UOM-unit-price">
                <PriceDisplay value={unitPrice} />
            </div>
        )
    }
    return (<></>);
}
