import * as types from './../Types/OrderTypes';
import * as freightTypes from './../Types/FreightTypes';
import { UpdateProductOptionDisplayPrice } from './../Types/ProductDetailsTypes';
import { CartItem, OrderItemNote, OrderStateType } from '../Reducers/App_Reducers/OrderReducer';
import axios from 'axios';
import { RefreshUserToken } from './../Actions/AuthActions';
import APIHost from '../../APIHost';
import ApiUtil from '../../Utils/ApiUtil';
import PromotionsHelper from '../../Utils/PromotionsHelper';
import AppliedOrderLineDiscount from "../../Interfaces/PromoMatrix/AppliedOrderLineDiscount";
import CalculatePromotionsRequest from '../../Interfaces/PromoMatrix/CalculatePromotionsRequest';
import { PaymentMethodType } from '../../Enums/PaymentMethods/PaymentMethodType';
import { GetLineItemDiscount } from '../../Utils/ProductsHelper';
import _ from 'lodash';
import { ProductDetailsOption } from '../Reducers/App_Reducers/ProductDetailsReducer';

const apiHost = new APIHost();
const apiUtil = new ApiUtil();

let cancel = axios.CancelToken.source();

export const CalculatePromotions = (addProductDetailsReducerProduct = false) => 
    (dispatch: any, getState: any) => {
    const state =  getState();
    const productDetails = state.productDetailsReducer.product;
    const productId = productDetails?.id;

    const itemsForPromotion =  _.cloneDeep([...state.orderReducer.orderItems]);
    const productOptions : ProductDetailsOption[] = _.cloneDeep(productDetails?.options);
    // opening for first time, add product to order
    if(addProductDetailsReducerProduct 
        && productOptions && !itemsForPromotion.some((item: any) => item.productId === productId)){
            let item : CartItem = {
                productId: productDetails.id,
                product: productDetails,
                productOptions: productOptions,
                totalItems: 0
            };
        itemsForPromotion.push(item)
    }

    const orderLineItems = PromotionsHelper.MapOrderItemsForPromotions(itemsForPromotion);
            
    if(Array.isArray(orderLineItems) && orderLineItems.length > 0){
        dispatch({
            type: types.Calculate_Promotions,
            payload: { addProductDetailsReducerProduct: addProductDetailsReducerProduct }
        });
        PromotionsHelper.AssignRowIdToOrderedLineItems(orderLineItems);
        const headers = apiUtil.DefaultHeaders(getState().authReducer?.subscribedToken);

        if (headers === null) {
            return;
        }

        const calcPromoRequest : CalculatePromotionsRequest = {
            moduleId: apiUtil.GetModuleId(),
            listOfOrderLines: orderLineItems
        };

        if (cancel) {
            // Cancel the previous request before making a new request
            cancel.cancel();
          }
          
          cancel = axios.CancelToken.source();
      
        const axiosConfig = {
            headers: headers.headers, 
            cancelToken: cancel.token
          };

        dispatch({
            type: types.Calculate_Promotions,
            payload: { isLoading: true }
        });

        axios.post(apiHost.PromoMatrixAPI("CalculatePromotions"), calcPromoRequest, axiosConfig).then(res => {

            const orderItemsUpdatedWithPromoValues = 
                PromotionsHelper.MapBasketBackToOrderItems(res.data.basket, itemsForPromotion);

            let productOptions = orderItemsUpdatedWithPromoValues
                .filter((x : CartItem) => x.productId === productId)[0]?.productOptions;
                

            if(productOptions){
               dispatch({
                    type: UpdateProductOptionDisplayPrice,
                    payload: productOptions
                });
            }

            if (orderItemsUpdatedWithPromoValues){

                let productsWithQuantity = orderItemsUpdatedWithPromoValues?.filter(item => item.totalItems > 0);
                productsWithQuantity.forEach((x: any) => {
                    x.productOptions = x.productOptions?.filter((item: any) => item.productQuantity > 0);
                })

                dispatch({
                    type: types.Calculated_Promotions,
                    payload: productsWithQuantity
                });
            }

            dispatch({
                type: types.Calculate_Promotions,
                payload: { isLoading: false }
            });

        })
        .catch(error => {
            if (axios.isCancel(error)) {
                return;
            }

            dispatch({
                type: types.Calculate_Promotions,
                payload: { isLoading: false }
            });

            if (error.response) {
                let errorStatus = error.response.status;
    
                switch (errorStatus) {
                    case 401:
                        dispatch(RefreshUserToken());
                        break;
                    
                    default:
                        return;
                }
            } else {
                return;
            }
        });
    }
}

export const IncrementOrderQuantity = (option: any, product: any, increment: number = 1) => (dispatch: any) => {    

    dispatch({
        type: types.Increment_Order_Quantity,
        payload: {productOption: option, product, increment}
    });
}

export const DecrementOrderQuantity = (option: any, product: any) => (dispatch: any) => {
    dispatch({
        type: types.Decrement_Order_Quantity,
        payload: {productOption: option, product: product}
    });
}

export const SetOrderQuantity = (option: any, product: any, quantity: number) => (dispatch: any) => {    
    dispatch({
        type: types.Set_Order_Quantity,
        payload: {productOption: option, product: product, quantity: quantity}
    });
}

export const RemoveOrderProduct = (productId: number, optionId: number | null, sizeId: number | null, uomId: number | null) => (dispatch: any) => {

    dispatch({
        type: types.Remove_Order_Product,
        payload: {productId: productId, optionId: optionId, sizeId: sizeId, uomId: uomId}
    });
}

export const ClearCart = () => (dispatch: any) => {

    dispatch({
        type: types.Clear_Cart
    });
}

export const UpdatePoNumber = (poNumberInput: string) => (dispatch: any) => {
    dispatch({
        type: types.Update_Purchase_Order_Number,
        payload: { poNumber: poNumberInput }
    });
}

export const UpdateDeliveryDate = (deliveryDateInput: Date | null) => (dispatch: any) => {
    dispatch({
        type: types.Update_Delivery_Date,
        payload: { deliveryDate: deliveryDateInput }
    });
}

export const UpdateDeliveryInstructions = (deliveryInstructionsInput: string) => (dispath: any) => {
    dispath({
        type: types.Update_Delivery_Instructions,
        payload: { deliveryInstructions: deliveryInstructionsInput }
    });
}

export const UpdateDeliveryAddress = (deliveryAddressInput: any) => (dispatch: any) => {
    dispatch({
        type: types.Update_Delivery_Address,
        payload: { deliveryAddress: deliveryAddressInput }
    });
}

export const CreateOrder = (order: any, userInfo: any, freightPrice: number, freightDescription: string) => (dispatch: any) => {

    var headers = apiUtil.DefaultHeaders('');

    if (headers === null) {
        return;
    }

    let lineItems: { productId: number; optionId: number; sizeId: number; quantity: number; description: string; uomId: number | null; 
        uomSize: number | null; contractPrice: number; discount: number; appliedDiscounts: AppliedOrderLineDiscount[]; }[] = [];
    
    order.orderItems.forEach((product: any) => {
        product.productOptions.forEach((option: any) => {
            const description = order.orderItemNotes.find((orderItemNote: OrderItemNote) => orderItemNote.optionId === option.optionId && orderItemNote.uomId === option.uomId)?.notes;
            lineItems.push(
                {
                    productId: product.productId,
                    optionId: option.optionId,
                    sizeId: option.sizeId,
                    quantity: option.productQuantity,
                    description,
                    uomId: option.uomId,
                    uomSize: option.uomSize,
                    contractPrice: option.contractPrice ? option.contractPrice : null,
                    discount: GetLineItemDiscount(option),
                    appliedDiscounts: option.appliedDiscounts
                })
        })
    });

    var deliveryDate = null;

    if (order.orderDetails.deliveryDate != null) {
        var date = new Date( Date.parse(order.orderDetails.deliveryDate) );

        var dateEndOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
        
        deliveryDate = dateEndOfDay.toUTCString();
    }

    const paymentMethod = order.orderDetails?.paymentMethod;

    let postBody = {
        moduleId: apiUtil.GetModuleId(),
        paymentMethod: paymentMethod === PaymentMethodType.PayOnline ? null : "On Account",
        billingAddress: {
            company: userInfo.billingAddress.company,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            address1: userInfo.billingAddress.address1,
            address2: userInfo.billingAddress.address2,
            city: userInfo.billingAddress.city,
            state: userInfo.billingAddress.state,
            postCode: userInfo.billingAddress.postCode,
            country: userInfo.billingAddress.country
        },
        deliveryAddress: {
            company: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.company : userInfo.deliveryAddress.company,
            firstName: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.firstName : userInfo.firstName,
            lastName: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.lastName : userInfo.lastName,
            address1: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.address1 : userInfo.deliveryAddress.address1,
            address2: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.address2 : userInfo.deliveryAddress.address2,
            city: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.city : userInfo.deliveryAddress.city,
            state: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.state : userInfo.deliveryAddress.state,
            postCode: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.postCode : userInfo.deliveryAddress.postCode,
            country: order.orderDetails.deliveryAddress ? order.orderDetails.deliveryAddress.country : userInfo.deliveryAddress.country
        },
        lineItems: lineItems,
        EstimatedTimeOfDelivery: deliveryDate,
        DeliveryInstructions: order.orderDetails.deliveryInstructions,
        CustomerOrderNumber: order.orderDetails.poNumber,
        FreightPrice: freightPrice,
        FreightDescription: freightDescription,
    };

    dispatch({
       type: types.Creating_Order
    });

    axios.post(apiHost.CheckoutAPI("CreateOrder"), postBody, headers).then(res => {

        let response = res.data;

        if (response.success) {
            dispatch({
                type: types.Create_Order_Success,
                payload: {
                    reference: response.reference,
                    id: response.id
                }
            });

            if (paymentMethod === PaymentMethodType.PayOnline) {
                dispatch({
                    type: types.Redirect_To_Payment,
                    payload: {
                        shouldRedirectToPayment: true
                    }
                });
            }
            else {
                dispatch(ForceStopDisplayingPlacingOrder());
                dispatch({
                    type: types.Redirect_To_Confirmation,
                    payload: {
                        shouldRedirect: true
                    }
                });
            }
        }
        else {
            
            let errorMessage = "An unknown error occurred.";

            if (response.errors) {
                errorMessage = response.errors[0].description;
            }

            dispatch(SetOrderFailAndErrorMessage(errorMessage));
        }

    }).catch((error) => {
        if (error.response) {
            let errorStatus = error.response.status;

            switch (errorStatus) {
                case 401:
                    dispatch(SetOrderFailAndErrorMessage(''));
                    dispatch(RefreshUserToken());
                    break;
                
                default:
                    dispatch(SetOrderFailAndErrorMessage(error.message));
                    break;
            }
        } else {
            dispatch(SetOrderFailAndErrorMessage(error.message));
        }
    });
}

export const OrderCompletionSuccessful = () => (dispatch: any) => {
    dispatch({
        type: types.Order_Completion_Successful
    });
    dispatch({
        type: types.Clear_Cart
    });
    dispatch({
        type: freightTypes.Reset_Freight_Options
    });
}

export const ResetCheckoutState = () => (dispatch: any) => {
    dispatch({
        type: types.Reset_Checkout_State
    });
}

export const UpdateOrderItemNotes = (orderItemOptionId: number, productId: number, uomId: number | null, notes: string) => (dispatch: any) => {
    dispatch({
        type: types.Update_Order_Item_Notes,
        payload: { orderItemOptionId, notes, productId, uomId }
    });
}

export const DeleteOrderItemNotes = (orderItemOptionId: number, productId: number, uomId: number | null) => (dispatch: any) => {
    dispatch({
        type: types.Delete_Order_Item_Notes,
        payload: { orderItemOptionId, productId, uomId }
    });
}

export const Reorder = (orderId: number) => (dispatch: any) => {

    var headers = apiUtil.DefaultHeaders('');

    if (headers === null) {
        return;
    }

    let postBody = {
        moduleId: apiUtil.GetModuleId(),
        orderId: orderId
    };

    dispatch({
        type: types.Fetching_Reorder_Details
    });

    axios.post(apiHost.CheckoutAPI("Reorder"), postBody, headers).then(res => {

        let response = res.data;

        let count = 0;

        let unavailableCount = 0;

        response.orderItems.forEach((orderItem: any) => {

            let product = {
                id: orderItem.id,
                name: orderItem.name,
                styleCode: orderItem.styleCode,
                images: orderItem.images.map((image: any) => {
                    return ({
                        thumbnail: image !== null ? image.thumbnail : null,
                        medium: image !== null ? image.medium : null,
                        full: image !== null ? image.full : null
                    });
                }),
                taxRate: orderItem.taxRate,
                allowOverselling: orderItem.allowOverselling
            };

            orderItem.orderOptions.forEach((orderOption: any) => {

                let quantityOrdered: number = orderOption.quantityOrdered;

                if (orderItem.allowOverselling === false && orderOption.stock <= 0) {
                    unavailableCount += quantityOrdered;
                    return;
                }

                let option = {
                    optionId: orderOption.optionId,
                    sizeId: orderOption.sizeId,
                    code: orderOption.code,
                    option1: orderOption.option1,
                    option2: orderOption.option2,
                    option3: orderOption.option3,
                    size: orderOption.size,
                    uomId: orderOption.uomId,
                    uomSize: orderOption.uomSize,
                    price: orderOption.price,
                    specialPrice: orderOption.specialPrice,
                    image: orderOption.image != null ? orderOption.image.thumbnail : null,
                    stock: orderOption.stock
                };
    
                if (orderItem.allowOverselling === false && orderOption.stock < quantityOrdered) {
                    unavailableCount += (quantityOrdered - orderOption.stock);
                    quantityOrdered = orderOption.stock;
                }

                count += quantityOrdered;
    
                dispatch(IncrementOrderQuantity(option, product, quantityOrdered));
            });
        });
        
        dispatch(CalculatePromotions());

        dispatch({
            type: types.Reorder_Details_Success,
            payload: {
                itemsCount: count,
                unavailableItemsCount: unavailableCount
            }
        });

    }).catch((error) => {
        if (error.response) {
            let errorStatus = error.response.status;

            switch (errorStatus) {
                case 401:
                    dispatch({
                        type: types.Reorder_Details_Fail,
                        payload: {
                            message: ''
                        }
                    });
                    dispatch(RefreshUserToken());
                    break;

                default:
                    dispatch({
                        type: types.Reorder_Details_Fail,
                        payload: {
                            message: error.message
                        }
                    });
                    break;
            }
        } else {
            dispatch({
                type: types.Reorder_Details_Fail,
                payload: {
                    message: error.message
                }
            });
        }
    });
}

export const ResetReorderState = () => (dispatch: any) => {
    dispatch({
        type: types.Reorder_Details_Reset
    });
}

export const ResetOrderPaymentRedirect = () => (dispatch: any) => {
    dispatch({
        type: types.Redirect_To_Payment,
        payload: {
            shouldRedirectToPayment: false
        }
    });
}

export const UpdateOrderPaymentMethod = (paymentMethod?: PaymentMethodType) => (dispatch: any) => {
    dispatch({
        type: types.Update_Order_Payment_Method,
        payload: { paymentMethod }
    });
}

export const ForceStopDisplayingPlacingOrder = () => (dispatch: any) => {
    dispatch({
        type: types.Force_Stop_Display_Placing_Order_Message
    });
}

export const SetOrderFailAndErrorMessage = (errorMessage : string) => (dispatch: any) => {
    dispatch({
        type: types.Create_Order_Fail,
        payload: {
            errorMessage: errorMessage
        }
    });
}

export const SetPaymentFailed = (paymentFailed: boolean) => (dispatch: any) => {
    dispatch({
        type: types.Set_Payment_Failed,
        payload: {
            paymentFailed: paymentFailed
        }
    });
}